import React from 'react'
import CareerTestimonials from './CareerTestimonials'

const CareerIntro = ({ employeesNodes }) => (
  <div>
    <section id="work" className="bg-primary">
      <div className="overlay overlay-dark" />
      <div className="container" style={{ marginTop: '100px' }}>
        <h1 className="text-center text-lowercase display-3">Work at ZAVEN</h1>
        <div className="lead seo-lead text-center career-intro-text">
          We are looking for&nbsp;
          <strong className="text-nowrap">full stack developers</strong>
          <span> and </span>
          <strong className="text-nowrap">UX/UI designers</strong>,<br />
          skilled in web and mobile technologies.
        </div>
        <CareerTestimonials employeesNodes={employeesNodes} />
      </div>
    </section>
  </div>
)

export default CareerIntro
