import React from 'react'
import { faGooglePlusG, faLinkedin } from '@fortawesome/fontawesome-free-brands'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CareerTestimonials = ({ employeesNodes }) => {
  const getIcon = iconName => {
    switch (iconName) {
      case 'fa-google-plus-g':
        return faGooglePlusG
      case 'fa-linkedin':
        return faLinkedin
      default:
        return null;
    }
  }

  const renderTestimonials = nodes => {
    return nodes.map(({ node }, index) => (
      <div key={index} className={`carousel-item ${index === 0 && 'active'}`}>
        <div className="media">
          <div className="mr-3">
            <img
              src={node.avatar.childImageSharp.fluid.src}
              alt={node.name}
              className="rounded-circle avatar"
            />
          </div>
          <div className="media-body">
            <p className="message career-intro-text">{node.message}</p>
            <p className="author career-intro-text">
              <span className="d-none d-sm-inline">&mdash;&nbsp;</span>
              <strong>{node.name}</strong>
              ,&nbsp;
              <br className="d-sm-none" />
              {node.role}
              <span className="d-none social-icons d-md-inline-block ml-2">
                {node.social &&
                  node.social.map((social, index) => (
                    <a
                      key={index}
                      href={social.url}
                      className="link-unstyled"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={getIcon(social.icon_class)}
                        style={{ margin: '0 0.1rem' }}
                      />
                    </a>
                  ))}
              </span>
            </p>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-12 offset-md-0">
          <div id="testimonials-employees" className="testimonials">
            <div
              id="testimonials-employees-carousel"
              className="carousel slide"
              data-ride="carousel"
            >
              <hr />
              <div>
                <div className="card-block">
                  <div className="carousel-inner" role="listbox">
                    {renderTestimonials(employeesNodes)}
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href={'#testimonials-employees-carousel'}
                  role="button"
                  data-slide="prev"
                >
                  <span className="icon-prev" aria-hidden="true" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href={'#testimonials-employees-carousel'}
                  role="button"
                  data-slide="next"
                >
                  <span className="icon-next" aria-hidden="true" />
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerTestimonials
