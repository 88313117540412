import React from 'react'
import NavBar from '../components/common/NavBar'
import CareerIntro from '../components/about/career/CareerIntro'
import JobsContainer from '../components/about/career/JobsContainer'
import Helmet from 'react-helmet'
import Layout from '../layouts';
import { graphql } from "gatsby"

const Career = ({ data, location }) => {
  const { phone, email, emailWork } = data.contactCareer.contact
  const allTechnologiesNodes = data.all_technologies.edges
  const careerBenefitsNodes = data.allCareerBenefitsJson.edges
  const employeesNodes = data.employees.edges
  const jobNodes = data.jobs.edges

  //TODO remove margin override after resolving the scss problem
  return (
    <Layout>
      <Helmet
        title={'Work at Zaven | Zaven'}
        meta={[
          {
            name: 'description',
            content:
              'Are you looking for a job as a Web App Developer? Browse our collection of full-stack developers’ listings or see similar jobs in engineering.',
          },
          {
            name: 'keywords',
            content:
              'full stack developers, web development Poland, developers outsourcing, top mobile developers Poland',
          },
        ]}
      />
      <NavBar
        phoneNumber={phone}
        email={email}
        transparentBackground
        style={{ marginBottom: '-98px' }}
        location={location}
      />
      <CareerIntro employeesNodes={employeesNodes} />
      <JobsContainer
        jobsNodes={jobNodes}
        allTechnologiesNodes={allTechnologiesNodes}
        careerBenefitsNodes={careerBenefitsNodes}
        emailWork={emailWork}
      />
    </Layout>
  )
}

export default Career

export const CareerQuery = graphql`
  query CareerQuery {
    contactCareer: dataJson {
      ...Contact
    }
    employees: allEmployeesJson(filter: { message: { ne: null } }) {
      ...Employees
    }
    jobs: allJobsYaml(filter: { active: { eq: true } }) {
      ...Jobs
    }
    all_technologies: allTechnologiesJson {
      ...Technologies
    }
    allCareerBenefitsJson {
      ...Benefits
    }
  }
`
