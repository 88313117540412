import React from 'react'
import { Link } from 'gatsby'
import BenefitsContainer from "./BenefitsContainer"

const JobsContainer = ({
  jobsNodes,
  allTechnologiesNodes,
  emailWork,
  careerBenefitsNodes,
}) => {

  const renderJobs = nodes => {
    return nodes.map(({ node }, index) => (
      <div key={index} className='col-sm-6'>
        <Link
          to={`/career${node.fields.slug.slice(0, -1)}`}
          className='card card-outline card-job link-unstyled'
        >
          <div className='card-body'>
            <h4 className='card-job-title'>{node.title}</h4>
            <p className='text-muted text-sm text-uppercase'>
              {node.job_location}
            </p>
            <div className='technologies'>
              {node.job_technologies &&
                allTechnologiesNodes
                  .filter(t => node.job_technologies.includes(t.node.short))
                  .map(({ node }, index) => (
                    <span key={index} className='technology technology-sm'>
                      <img
                        src={node.image.childImageSharp.fluid.src}
                        alt={node.title}
                        style={{ height: '30px' }}
                      />
                    </span>
                  ))
              }
            </div>
          </div>
        </Link>
      </div>
    ))
  }

  return (
    <section className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-md-12'>
            <h2>Open positions</h2>
            <hr />
            <div className='row'>
              {renderJobs(jobsNodes)}
              <div className='col-sm-6'>
                <div className='card card-outline card-job link-unstyled'>
                  <div className='card-body'>
                    <h4 className='card-job-title'>
                      Not the right position, but still want to work with us?
                    </h4>
                    <p>
                      Let us know:&nbsp;
                      <a href={`mailto:${emailWork}`}>{emailWork}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-12'>
            <h2>Benefits</h2>
            <hr />
            <BenefitsContainer benefitsNodes={careerBenefitsNodes} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobsContainer
